<template>
  <div
    class="unpaid-content"
    :style="{ 'border-radius': borderRadius }"
  >
    <div class="unpaid-content__title">
      <span v-html="props.orderItem?.title"></span>
    </div>
    <div
      class="unpaid-content__wrap-countdown"
      v-html="getCountDownTips"
    >
    </div>
    <ProductPanel
      :order-item="props.orderItem"
      :is-double-view="isDoubleView"
    />

    <div
      :class="`unpaid-content__footer ${isDoubleView ? 'breath-effect' : ''}`"
    >
      <div
        class="unpaid-content__pay-btn"
        @click="handlePayNow"
      >
        <span v-html="props.orderItem?.clickButton.content || ''"></span>
      </div>
      <div
        v-if="props.orderItem?.freeShippingLabel"
        class="unpaid-content__tips"
      >
        <img
          class="unpaid-content__tips-icon"
          :src="getTipsIcon"
        />
        <div
          class="unpaid-content__tips-text"
        >
          {{ props.orderItem?.freeShippingLabel }}
        </div>
      </div>
      <img
        v-if="props.orderItem.freeShippingLabel"
        class="unpaid-content__triangle"
        :src="USER_INDEX_LOCAL_IMG.rectangleIcon" 
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits, getCurrentInstance, onMounted } from 'vue'
import { CountDown } from '@shein/common-function'
import ProductPanel from './ProductPanel.vue'
import { getBuriedData } from 'public/src/pages/user_index/service/utils.js'
import { USER_INDEX_LOCAL_IMG } from '../../../consts'
import { formatUrlQuery } from '../../../service/utils.js'
const { langPath } = gbCommonInfo

const countTimer = ref(new CountDown())

const props = defineProps({
  borderRadius: { type: String, default: '' },
  orderItem: { type: Object, default: () => {} }
})

const emits = defineEmits(['closeDialog'])

const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

onMounted(() => {
  /* 未支付订单倒计时 */
  const { expireCountdown } = props.orderItem || {}
  if (expireCountdown) {
    countTimer.value.start({
      seconds: expireCountdown,
      endFunc: () => {
      // 倒计时结束
      }
    })
  }
})

/* computed */
const isDoubleView = computed(() => props.orderItem.abtValue === 'B')
const getCountDownTips = computed(() => {
  const { H, M, S } = countTimer.value?.timeObj || {}
  const timeDom = `<span>${H}</span> : <span>${M}</span> : <span>${S}</span>`
  return `${props.orderItem?.countdownTips} ${timeDom}`
})

const getTipsIcon = computed(() => {
  const labelType = props.orderItem?.labelType || ''
  switch(+labelType) {
    case 1: // 包邮
      return USER_INDEX_LOCAL_IMG.freeShipping
    case 2: // 快速运输标签
      return USER_INDEX_LOCAL_IMG.fastFreight
    case 3: // 准时宝标签
      return USER_INDEX_LOCAL_IMG.complete
    case 4: // 运输时效
      return USER_INDEX_LOCAL_IMG.transportation
    case 5: // 免费退货标签
      return USER_INDEX_LOCAL_IMG.freeRefund
    default:
      return ''
  }
})

/* methods */
const handlePayNow = () => {
  emits('closeDialog')
  const { jumpUrl = '', jumpParams = {}, buriedObject = [] } = props.orderItem?.clickButton?.urlObject
  const { billno, page_from, from_type, show_selected_pay_method } = jumpParams
  const urlQuery = formatUrlQuery({
    goBack: 1,
    show_error_guide_payment: show_selected_pay_method,
    from_type,
    page_from
  })

  daEventCenter.triggerNotice({
    daId: '1-25-11-1003',
    extraData: getBuriedData(buriedObject, 2)
  })
  routerInstance.push(`${langPath}${jumpUrl}/${billno}?${urlQuery}`)
}

</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 375/100vw;
.unpaid-content {
  width: 328 / @unit;
  background: #FFF;
  border-bottom-left-radius: 10 / @unit;
  border-bottom-right-radius: 10 / @unit;
  margin-top: -1 / @unit;
  padding: 12 / @unit 16 / @unit 20 / @unit;
  box-sizing: border-box;
 
  &__title {
    color: var(---sui_color_gray_dark1, #000);
    font-size: 16 / @unit;
    font-weight: 700;
    text-align: center;
  }

  &__footer {
    margin-top: 20 / @unit;
    position: relative;
  }
  &__pay-btn{
    width: 100%;
    height: 44 / @unit;
    box-sizing: border-box;
    color: #FFF;
    text-align: center;
    font-size: 14 / @unit;
    font-weight: 700;
    background: #000;
    display: flex;
    padding: 9/ @unit 16 / @unit;
    justify-content: center;
    align-items: center;
  }
  &__tips {
    position: absolute;
    top: -12 / @unit;
    right: -5 / @unit;
    display: flex;
    height: 20 / @unit;
    padding: 0 12 / @unit;
    justify-content: center;
    align-items: center;
    gap: 4 / @unit;
    background: #198055;
  }
  &__tips-icon {
    width: 14 / @unit;
    height: 14 / @unit;
    background-size: cover;
  }
  &__tips-text {
    color: #FFFFFF;
    line-height: normal;
  }
  &__triangle {
    width: 5 / @unit;
    height: 5 / @unit;
    background-size: cover;
    position: absolute;
    top: 8 / @unit;
    right: -5 / @unit;
  }

  &__wrap-countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .1067rem 0;
    font-size: 12 / @unit;
    line-height: .3733rem;
    /deep/ span {
      background-color: #000;
      font-weight: 400;
      color: #FFFFFF;
      width: 0.4267rem;
      height: 0.4267rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: .0533rem;
      &:first-child {
        margin-left: .1067rem;
      }
    }
  }
}

@keyframes breathAnimation {
  0%, 100% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.04); /* 放大到106% */
  }
}

.breath-effect {
  animation: breathAnimation 2s ease-in-out infinite;
}
</style>
