var render = function render(){var _vm=this,_c=_vm._self._c;return _c('s-dialog',{staticClass:"gift-dialog-modal",style:({
    '--justify-content': _vm.productLength === 1 ? 'center' : (_vm.productLength === 2 ? 'flex-start' : 'space-between'),
  }),attrs:{"visible":_vm.showDialog,"close-on-click-modal":false,"immediately-render":false,"show-close":true,"modal-class":"gift-dialog-modal","append-to-body":""},on:{"update:visible":function($event){_vm.showDialog=$event},"close-from-icon":_vm.clickCloseIcon}},[(!_vm.hidden)?_c('div',{staticClass:"fire"},[_c('img',{attrs:{"src":_vm.popupInfo?.popupGif,"alt":""}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gift-border"}),_vm._v(" "),_c('img',{staticClass:"header-icon",attrs:{"src":_vm.popupInfo?.popupLogo,"alt":""}}),_vm._v(" "),_c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
      id: '1-25-15-1',
      data: {
        scene_type: _vm.freeGiftPopupInfo?.sceneType,
      }
    }),expression:"{\n      id: '1-25-15-1',\n      data: {\n        scene_type: freeGiftPopupInfo?.sceneType,\n      }\n    }"}],staticClass:"gift-background free-gift-animation"},[_c('div',{staticClass:"gift-main__title"},[_c('ResizeFont',[_c('ResizeFontText',{attrs:{"init-font-size":14,"resize-font-min":10,"resize-font-step":1,"content":_vm.popupInfo?.title}})],1)],1),_vm._v(" "),_c('div',{staticClass:"gift-sub__title"},[_c('ResizeFont',[_c('ResizeFontText',{staticClass:"gift-sub__title-value",attrs:{"init-font-size":22,"resize-font-min":18,"resize-font-step":1,"content":_vm.popupInfo?.subTitle}})],1)],1),_vm._v(" "),(_vm.freeGiftPopupInfo?.countDown)?_c('div',{staticClass:"expire-time"},[_c('div',{staticClass:"expire-text"},[_vm._v("\n        "+_vm._s(_vm.popupInfo?.countDownText || "Expires")+"\n      ")]),_vm._v(" "),(_vm.countTimer)?_c('BaseCountdown',{staticClass:"count-timer",attrs:{"count-timer":_vm.countTimer}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{ref:"carouselInner",staticClass:"gift-product__list",class:{
        'gift-product__list--animation': _vm.productLength > 1
      }},_vm._l((_vm.giftInfoList),function(item,index){return _c('product-item',{key:index,staticClass:"product-item",class:{'active': index === _vm.activeIndex},style:(_vm.productStyle(index)),attrs:{"item":item,"popup-info":_vm.popupInfo}})}),1)]),_vm._v(" "),_c('div',{directives:[{name:"tap",rawName:"v-tap",value:({
      id: '1-25-15-2',
      data: {
        scene_type: _vm.freeGiftPopupInfo?.sceneType,
      }
    }),expression:"{\n      id: '1-25-15-2',\n      data: {\n        scene_type: freeGiftPopupInfo?.sceneType,\n      }\n    }"}],staticClass:"pick-btn",on:{"click":_vm.goPrimePage}},[_vm._v("\n    "+_vm._s(_vm.popupInfo?.buttonText || 'Pick Now')+"\n    "),_c('sweep-light',{staticClass:"light"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }