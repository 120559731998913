<template>
  <div
    class="index-me-center"
  >
    <!-- nickname -->
    <div v-if="isLogin">
      <div
        class="aside-login mshe-flex"
      >
        <div class="upload-msg">
          <div
            class="user-name"
            @click="gotoAccountSetting"
          >
            <span
              role="link"
              tabindex="0"
              class="name"
            >
              {{ getNickName }}
            </span>
                
            <div
              v-if="!!props.userLevelInfo?.level"
              role="button"
              tabindex="0"
              aria-label="SHEIN VIP"
              class="user-vip"
              @click.stop="gotoVip"
            >
              <img
                class="vip-img"
                :src="props.userLevelInfo?.level_image?.url || ''"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="aside-login mshe-flex"
      DA-type="syncClick"
      DA-sa-name="signin_register"
      @click="gotoLogin"
    >
      <div class="upload-msg">
        <div class="user-name">
          <span
            tabindex="0"
            role="link"
            class="not-login-title"
          >
            {{ firstUpperCase(htmlDecode({ text: gbLanguage.SHEIN_KEY_PWA_15872 })) + ' >' }}
          </span>
        </div>
      </div>
    </div>

    <SLoading
      v-if="showRefreshLoading"
      show
      size="medium"
    />

    <!-- panel-wrap -->
    <div class="panel-wrap">
      <UserRightsPanel
        v-if="userRightsInfo"
        :rights-info="userRightsInfo"
      />
      <NewsPanel
        v-if="props.newsInfo"
        :news-info="props.newsInfo"
        :route-info="props.routeInfo"
      />
    </div>
    <MainServices 
      :personal-info="props.personalInfo"
      @router-push="routerPush"
    />
    <UserAssetsBottom
      :ready-to-init="isUserAssetsBottomDataReady"
      :new-user-benefits="newUserBenefits"
      :bottom-tips-info="bottomTipsInfo"
      @router-push="routerPush"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, defineEmits, watch } from 'vue'
import { useMapState, useMapMutations } from '../hooks/useVuex.js'
import { htmlDecode, firstUpperCase } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { JUMP_URL } from '../consts'

/* component */
import { Loading as SLoading } from '@shein/sui-mobile'
import UserRightsPanel from './UserRightsPanel/ModuleIndex.vue'
import MainServices from './MainServices.vue'
import NewsPanel from './NewsPanel.vue'
import UserAssetsBottom from './UserAssetsBottom/ModuleIndex.vue'

import { initNewUserBenefitsModule } from 'public/src/pages/user_index/hooks/new-user-benefits'

const storeState = useMapState(['isRisk', 'userInfo', 'isLogin'])

const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router
const { setStoreState } = useMapMutations(['setStoreState'])

const { 
  language: gbLanguage,
  langPath,
} = gbCommonInfo

const props = defineProps({
  isPageDataReady: { type: Boolean, default: false },
  showRefreshLoading: { type: Boolean, default: false },
  userLevelInfo: { type: Object, default: () => {}  },
  personalInfo: { type: Object, default: () => {}  },
  userRightsInfo: { type: Object, default: null  },
  bottomTipsInfo: { type: Object, default: () => {} },
  newsInfo: { type: Object, default: () => {} },
  routeInfo: { type: Object, default: () => ({}) },
})

const emits = defineEmits([
  'routerPush',
  'pointsOrCouponJumpEvt',
  'loginSucRedirection',
  'openRiskDrawer',
])

const {
  newUserBenefits,
  initNewUserBenefits,
  isNewUserBenefitsDataReady,
} = initNewUserBenefitsModule()

watch(() => props.isPageDataReady, (isReady) => {
  if (isReady) return
  initNewUserBenefits()
})

/* computed */
const isLogin = computed(() => storeState.isLogin.value)
const isUserAssetsBottomDataReady = computed(() => props.isPageDataReady && isNewUserBenefitsDataReady.value)
const getNickName = computed(() => storeState.userInfo.value?.username || '')

/* ------ methods ------ */

const gotoAccountSetting = () => {
  if (storeState.isRisk.value) {
    emits('openRiskDrawer')
    return
  }
  routerInstance.push({ path: `${langPath}${JUMP_URL.USER_ACCOUNT_SETTING}` })
  // 缺埋点 1-63-3-2 todo
}

const gotoVip = () => {
  if (storeState.isRisk.value) {
    emits('openRiskDrawer')
    return
  }
  routerInstance.push(`${langPath}/user/sheinvip`)
  daEventCenter.triggerNotice({
    daId: '1-25-11-16',
  })
}

const gotoLogin = () => {
  // 缺埋点 todo
  setStoreState({
    routerUrl: `${langPath}/user/index`
  })
  SHEIN_LOGIN.show({
    show: true,
    from: 'login',
    bi: 'login',
    ga: 'login',
    cb: (opt) => emits('loginSucRedirection', opt)
  })
}

const routerPush = (...args) => {
  emits('routerPush', ...args)
}
</script>

<style lang="less" scoped>
@w: 375/100vw;
@w2: 750/100vw;
.index-me-center {
  border-bottom: 20/75rem solid #f6f6f6;
  position: relative;
  z-index: @zindex-hack;

  .panel-wrap {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 37%);
    .spacing-top {
      margin-top: 0.2133rem;
    }
  }

  .tips-list {
    padding: 0 8/75rem 24/75rem;
    background: #FFF;

    .tips-arrow {
      display: flex;

      .arrow {
        flex: 1;
        justify-content: center;
        display: flex;
        align-items: center;

        .arrow-icon {
          border: 12/75rem solid transparent;
          border-bottom-color: #fff1d5;
          width: 12/75rem;
          height: 12/75rem;
          display: inline-block;
        }
      }
    }
  }

  .aside-login {
    height: 88/75rem;
    padding: 22/75rem 24/75rem;
    white-space: nowrap;

    &_sheinbg {
      background: transparent;
    }

    .upload-msg {
      .flexbox();
      .align-center();
      width: 100%;

      .user-name {
        color: var(--config-color, @color_black_mirror);
        font-weight: bold;
        width: 100%;
        .font-dpr(40px);
        .flexbox();
        .align-center();

        .name {
          .text-overflow();
          display: inline-block;
          vertical-align: bottom;
        }

        .not-login-title {
          color: var(--config-color, #222);
          display: inline-block;
          font-weight: bold;
          .font-dpr(40px);
        }

        .user-vip {
          display: inline-block;
          height: 40/75rem;
          line-height: 40/75rem;
          position: relative;
          bottom: 0.038rem;
          .left(8/75rem);

          > img.vip-img {
            height: 100%;
            width: auto;
          }
        }
      }
    }

  }

  .mshe-flex {
    width: 100%;
    .flexbox();
  }
}
</style>
