
import { getLocalStorage } from '@shein/common-function'

// 校验缓存时间是否失效
const timeCheck = (time, currentTime, type) => {
  const { SiteUID, user } = gbCommonInfo || {}
  const cacheKey =
    SiteUID + '-' + (user?.member_id || '') + '-'
  // 临期key
  const nearExpiredKey = cacheKey + 'primeV2RenewDialog'
  // 逾期key
  const ExpiredKey = cacheKey + 'primeV2ExpiredRenewDialog'

  let cacheTimeKey
  if (type == 'nearExpired') {
    cacheTimeKey = nearExpiredKey
  } else {
    cacheTimeKey = ExpiredKey
  }
  const cacheTime = +getLocalStorage(cacheTimeKey)

  if (!cacheTime) {
    // 不存在缓存，触发弹窗
    return true
  } else {
    // 先判断缓存是否失效，如果未失效，不触发弹窗,返回false
    // 如果缓存失效,触发弹窗，存储当前时间，返回true

    // 当前时间 2024年10月12日 10：20:30
    // 0天失效，2024年10月13日 10:20:30
    // 24 小时/天 × 60 分钟/小时 × 60 秒/分钟 × 1000 毫秒/秒
    // 缓存的开始时间
    const cacheDayStartTime = new Date(cacheTime).getTime()
    // 当天的开始时间
    const currentDayStartTime = new Date(currentTime).getTime()
    // 命中缓存
    if (currentDayStartTime - cacheDayStartTime < time) {
      return false
    } else {
      return true
    }
  }
}

export const canShowRenewDialog = (renewPopUpInfo) => {
  // 插入场景
  // 当以下条件同时满足时，不弹付费会员续费引导弹窗：
  // ① 无省额
  // /v2/User/prime/paidDetail接口的req_currency_price字段 < 0
  // ② 无会费价&会费券
  // /v2/User/prime/queryMemberBuyProduct接口的total_discount_price < 0
  // ③ 无买赠活动
  // /v2/User/prime/queryMemberBuyProduct接口的gift_type≠1
  const req_currency_price = renewPopUpInfo.req_currency_price || 0
  const total_discount_price = renewPopUpInfo.total_discount_price || 0
  const flag1 = req_currency_price <= 0
  const flag2 = total_discount_price <= 0
  const flag3 = renewPopUpInfo.have_gift_flag

  if (flag1 && flag2 && flag3) {
    return false
  }
  const { RENEW_DIALOG_DELAY } = gbCommonInfo || {}

  // 2. 判断是临期还是逾期，
  // 若差值≥0，则为临期状态，将差值换算为临期天数
  // 若临期天数为0~3天，用户每天首次访问个人中心，均弹出1次
  // 若临期天数为4~30天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若差值<0，则为逾期状态，将差值换算为逾期天数
  // 若逾期天数为0~7天，用户每间隔2天 首次访问个人中心，弹出1次
  // 若逾期天数为8~21天，用户每间隔5天 首次访问个人中心，弹出1次
  // 若逾期天数为22~60天，用户每间隔10天 首次访问个人中心，弹出1次
  const { overdue_rules = [], near_expired_rules = [] } = RENEW_DIALOG_DELAY
  const currentTime = new Date().getTime()
  const time = renewPopUpInfo.total_end_time
  let days = time / 1000 / 60 / 60 / 24
  let flag, currentRule
  if (days >= 0) {
    currentRule = near_expired_rules.find(
      rule => days >= rule.left_bound && days < rule.right_bound
    )
  } else {
    days = -days
    currentRule = overdue_rules.find(
      rule => days >= rule.left_bound && days < rule.right_bound
    )
  }
  if (currentRule) {
    flag = timeCheck(
      currentRule.interval_time * 1000,
      currentTime,
      time >= 0 ? 'nearExpired' : 'expired'
    )
  } else {
    flag = false
  }
  return flag
}
