<template>
  <div
    class="index-me-header mshe-z-header"
  >
    <div class="header-right">
      <div
        v-enterkey
        role="button"
        tabindex="0"
        aria-label="settings"
        class="waves-btn waves-btn-auto waves-effect waves-classic"
        DA-type="syncClick"
        @click="onSettingClick"
      >
        <sui_icon_nav_setting_24px
          size="24px"
          class="me-header-icon"
        />
      </div>
    </div>
  </div>
</template>

<script setup name="MeHeader">
import { defineEmits } from 'vue'
import { sui_icon_nav_setting_24px } from '@shein-aidc/icon-vue2'

import { JUMP_URL } from '../consts.js'

const emits = defineEmits(['routerPush'])

const onSettingClick = () => {
  emits('routerPush', { target: JUMP_URL.USER_SETTING })
}
</script>

<style lang="less" scoped>
.index-me-header {
  height: 1.17rem;
  padding: 0 0.1rem;
  background-color: #fff;
  .flexbox();
  .align-center();
  .space-between();
  &_sheinbg {
    background: transparent;
    position: relative;
    z-index: @zindex-hack;
  }
 

  &.scrolling {
    border-bottom: 1px solid rgba(229, 229, 229, 1);
  }

  .header-left,
  .header-right {
    .flexbox();
    .align-center();
    flex: 1;
    height: 1.17rem;
  }

  .header-right {
    .txt-r();
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .me-header-icon {
    margin-right: 6px;
  }
}
</style>
