<template>
  <div class="gift-product">
    <div class="gift-product__item">
      <span class="gift-product__item-left-top">
        {{ popupInfo.productTagText }}
      </span>
      <base-img
        class="gift-product__img"
        fit="cover"
        :img-src="item.giftImg"
        img-design-width="950"
        :first-screen="false"
      />
      <div class="gift-product__bottom">
        <div class="gift-product__price">
          <ResizeFont>
            <ResizeFontText
              :init-font-size="13"
              :resize-font-min="8"
              :resize-font-step="1"
              :content="item.giftPrice"
            />
          </ResizeFont>
        </div>
        <div class="gift-product__original-price">
          <ResizeFont>
            <ResizeFontText
              :init-font-size="7.5"
              :resize-font-min="6"
              :resize-font-step="1"
              :content="item.giftOriginalPrice"
            />
          </ResizeFont>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import ResizeFont from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFontText.vue'

export default {
  name: 'ProductItem',
  components: {
    BaseImg,
    ResizeFont,
    ResizeFontText
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    popupInfo: {
      type: Object,
      default: () => ({})
    }
  },
}
</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 100/375vw;
.gift-product {
  
  &__item {
    position: relative;
    height: 100%;
  }
  
  &__img {
    width: 100%;
    height: 100%;
    border-radius: 6 * @unit;
  }
  
  &__item-left-top {
    position: absolute;
    z-index: 1;
    text-align: center;
    top: 0;
    left: 0;
    width: 36 * @unit;
    height: 16.5 * @unit;
    font-size: 10.5 * @unit;
    line-height: 16 * @unit;
    background: rgba(250, 99, 56, 1);
    border-radius: 6 * @unit 0px 6 * @unit 0px;
    color: #fff;
  }
  
  &__bottom {
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;
    align-items: baseline;
    margin-top: 4 * @unit;
  
    [mir=rtl] & {
      justify-content: right;
    }
    
    .gift-product__price {
      font-weight: 700;
      //font-size: 13 * @unit;
      color: #FA6338;
    }
    
    .gift-product__original-price {
      .text-overflow();
      font-size: 7.5 * @unit;
      color: #873C00;
      text-decoration: line-through;
      margin-left: 4 * @unit;
    }
  }
}
</style>
