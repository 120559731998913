<template>
  <div
    v-if="getOrdersList.length > 0"
    ref="unpaidOrderRef"
    :class="[
      'unpaid-order-box',
      `${getOrdersList.length === 1 && getUnpaidOrdersFloor.length > 1 ? 'unpaid-order-box__single':''}`,
      `${unpaidOrderActiveClass}`
    ]"
    da-expose-code="unpaidOrder"
  >
    <swiper-container
      ref="swiperRef"
      class="unpaid-swiper-order" 
      init="false"
      no-swiping="true"
      centered-slides="true"
      observer="true"
      autoplay-delay="3000"
      :observe-slide-children="true"
      autoplay-disableOnInteraction="false"
      :loop="getOrdersList.length > 1"
      :pagination="getOrdersList.length > 1"
      destroy-on-disconnected="false"
      @swiperslidechangetransitionend="sliderChangeCallback"
    >
      <swiper-slide
        v-for="(item) in getOrdersList"
        :key="item.billNo"
        v-expose="{ id: '1-25-11-18' }"
        class="unpaid-slide-order"
      >
        <div
          v-expose="{
            id: '1-25-11-1000',
            data: {
              item
            }
          }"
          class="unpaid-slide-order__container"
          @click.stop="() => gotoDetail(item)"
        >
          <template v-if="item.orderType === 1">
            <template v-if="isNewFloor">
              <UnpaidOrderNew
                :item="item"
                :unpaidOrderActiveClass="unpaidOrderActiveClass"
                @countdownEnd="() => countdownEnd?.push(item.billNo)"
              />
            </template>
            <template v-else>
              <UnpaidOrder
                :item="item"
                @countdownEnd="() => countdownEnd?.push(item.billNo)"
              />
            </template>
          </template> 
          <template v-if="item.orderType === 2">
            <!-- UnReviewOrder -->
            <div class="unreview__container">
              <div class="order-info">
                <div class="order-img">
                  <img
                    class="first-img"
                    :src="item.goodImage"
                    alt=""
                  />
                </div>
                <div class="order-content">
                  <div v-html="item.upTips"></div>
                </div>
              </div>
              <div class="order-btn">
                <SButton
                  :type="['primary','H48PX']"
                  block
                >
                  {{ htmlDecode({ text: item.clickButton?.content || '' }) }}
                </SButton>
              </div>
            </div>
          </template>
        </div>
      </swiper-slide>
    </swiper-container>
    <!-- UnpaidDialog -->
    <template v-if="isNewUnpaidDialog">
      <UnpaidDialogNew
        v-if="dialogOrderItem"
        :order-item="dialogOrderItem"
        :unpaid-order-rects="unpaidOrderRects"
        @clickCloseDialog="clickCloseDialog"
      />
    </template>
    <template v-else>
      <UnpaidDialog
        v-if="dialogOrderItem"
        :order-item="dialogOrderItem"
      />
    </template>
  </div>
</template>

<script setup>
// 楼层
import { computed, watch, ref, getCurrentInstance, onMounted, nextTick, onActivated } from 'vue'
import { htmlDecode, getLocalStorage, setLocalStorage } from '@shein/common-function'
import { throttle } from '@shein/common-function'
import { clearPoskey } from '@shein-aidc/basis-abt-router'
import { getBuriedData } from 'public/src/pages/user_index/service/utils.js'
import UserInfoManager from 'public/src/services/UserInfoManager'
import UnpaidDialogNew from './unpaid/dialog/UnpaidDialogNew.vue'
import UnpaidDialog from './unpaid/dialog/UnpaidDialog.vue'
import { Pagination, Autoplay } from 'swiper/modules'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import UnpaidOrder from './unpaid/order/UnpaidOrder.vue'
import UnpaidOrderNew from './unpaid/order/UnpaidOrderNew.vue'
import { useMapState } from '../hooks/useVuex.js'
import { formatUrlQuery } from '../service/utils.js'


const instance = getCurrentInstance()
const routerInstance = instance?.proxy?.$router

const { langPath, PUBLIC_CDN } = gbCommonInfo
const storeState = useMapState(['SiteUID'])

const swiperRef = ref(null)
const unpaidOrderRef = ref(null)
const swiperInstance = ref(null)
const countdownEnd = ref([]) // 倒计时订单
const exposeBillnos = ref([])
const dialogOrderItem = ref(null)
const unpaidDialogHasClicked = ref(false)
const unpaidOrderRects = ref({})
const realIndex = ref(0)


const props = defineProps({
  orderGroup: { type: Object, default: () => {} },  // 楼层数据
  orderPopup: { type: Object, default: () => {} }, // 弹窗数据
})

onMounted(() => {
  // 登录成功触发
  appEventCenter.$on('initUserInfo', () => {
    initPageEvt()
  })
  nextTick(() => {
    initPageEvt()
  })
  unpaidOrderListAbtDiversionReport()
})

onActivated(() => {
  swiperInstance.value?.loopDestroy()
  swiperInstance.value?.loopCreate()
  swiperInstance.value?.autoplay?.start()
})

/* computed */
// 待支付与待评论列表总和
const getOrdersList = computed(() => [].concat(getUnpaidOrdersFloor.value, getUnReviewOrdersFloor.value).filter(Boolean) || [])

// 待支付订单(楼层)
const getUnpaidOrdersFloor = computed(() =>
  props.orderGroup?.orders?.filter(item => item.orderType === 1 && [1, 2].includes(item.realOrderType))?.map(item => {
    if (Number(item.expireCountdown) > 0 && !countdownEnd.value.includes(item.billNo)) {
      return item
    }
    return false
  })?.filter(Boolean)
)
// 待评论订单(楼层)
const getUnReviewOrdersFloor = computed(() => props.orderGroup?.orders?.filter(item => item.orderType === 2))
// 未支付订单(弹窗)
const getUnpaidOrdersPopup = computed(() => props.orderPopup?.orderList)
const isNewFloor = computed(() => props.orderGroup?.orders?.[0]?.abtValue === 'On')
const isNewUnpaidDialog = computed(() => getUnpaidOrdersPopup.value?.[0]?.abtValue !== 'Default')
const unpaidOrderActiveClass = computed(() => {
  if (!!dialogOrderItem.value && isNewUnpaidDialog.value && isNewFloor.value) {
    return unpaidDialogHasClicked.value ? 'unpaid-order-box__show' : 'unpaid-order-box__hide'
  }
  return 'unpaid-order-box__default'
})

/* methods */
// 跳转到订单详情
const gotoDetail = (item) => {
  const { orderType, urlObject = {} } = item
  const { jumpUrl = '', jumpParams = {} } = urlObject
  if (orderType === 1) {
    routerInstance.push(`${langPath}${jumpUrl}/${jumpParams?.billno}?goBack=1`)
  } else {
    const urlQuery = formatUrlQuery({
      billno: jumpParams?.billno,
      review_page_location: jumpParams?.review_page_location
    })
    routerInstance.push(`${langPath}${jumpUrl}?${urlQuery}`)
  }
  const reportInfo = (item?.buriedObject || []).find(item => item.buriedType === 2 && item.buriedKey === 'order_floor')
  daEventCenter.triggerNotice({
    daId: '1-25-11-1001',
    extraData: reportInfo?.defaultParams,
  })
}

const initPageEvt = throttle({
  func: async function() {
    if (!isLogin()) { return false }
    clearPoskey({ posKeys: ['unpaidOrderMe'] })

    
    showDialog()
    if (getUnpaidOrdersFloor.value.length > 0) {
      // 未支付订单场景埋点
      daEventCenter.triggerNotice({
        daId: '1-22-11-1009',
        extraData: {
          scenes: 'unpaidOrderMe',
          order_id_list: getUnpaidOrdersFloor.value.map(item => item.billNo).join('`'),
        }
      })
      daEventCenter.triggerNotice({
        daId: '1-25-11-1006',
        extraData: {
          scenes: 'unpaidOrderMe',
          order_id_list: getUnpaidOrdersFloor.value.map(item => item.billNo).join('`'),
        }
      })
      analysisData(0)
      exposeData()
    }
  },
  wait: 100,
  options: {
    leading: false,
  }
})

const analysisData = (realIndex) => {
  const billNo = getOrdersList.value[realIndex]?.billNo
  if (exposeBillnos.value.includes(billNo)) return
  daEventCenter.triggerNotice({
    daId: '1-25-11-1000',
    extraData: getBuriedData(getOrdersList.value[realIndex]?.buriedObject, 1),
  })
  exposeBillnos.value.push(billNo)
}

const exposeData = () =>  {
  daEventCenter.triggerNotice({
    daId: '1-25-11-1012',
    extraData: {
      scene: isNewFloor.value ? 'new_floor' : 'unpaid'
    }
  })
}

const showDialog = () => {
  if (!getUnpaidOrdersPopup.value) return

  const memberId = UserInfoManager.get({ key: 'memberId', actionType: 'OrderPanelShowDialog' })
  const cacheKey = `unpaid_dialog_cache_${storeState.SiteUID.value}_${memberId}`
  const cache = getLocalStorage(cacheKey) || {}
  // 弹窗总共弹出次数
  if (Number(cache.popedNum) >= Number(props.orderPopup?.popNum)) {
    return
  }
  const currTime = Number.parseInt(Date.now() / 1000)
  let _dialogOrderItem = null
  ;(getUnpaidOrdersPopup.value || []).find(item => {
    const itemData = cache[item.billNo]
    if (itemData) {
      // 拿得到表示这个订单弹过了
      const showCount = Number(itemData.showCount || 0)
      const lastTime = Number(itemData.lastTime || 0)
      // 单个订单弹出次数小于配置次数且时间间隔大于配置时间
      const flag = (showCount < Number(props.orderPopup?.orderMostShow)) &&
        ((currTime - lastTime) > Number(props.orderPopup?.popIntervals))
      if (flag) {
        _dialogOrderItem = item
        return true
      }
      return false
    } else {
      // 从来没弹过这个订单
      _dialogOrderItem = item
      return true
    }
  })
  dialogOrderItem.value = _dialogOrderItem
  if (_dialogOrderItem) {
    // 有弹窗弹出
    const id = _dialogOrderItem.billNo
    setLocalStorage({
      key: cacheKey,
      value: {
        ...cache,
        popedNum: Number(cache.popedNum || 0) + 1,
        [id]: {
          showCount: Number(cache[id]?.showCount || 0) + 1,
          lastTime: currTime,
        }
      }
    })
  }
}

const initSwiper = (newVal, oldVal) => {
  if (!swiperInstance.value) {
    const swiperEl = swiperRef.value
    if (!swiperEl) return // 没有swiper数据直接返回
    const swiperConfig = {
      modules: [Pagination, Autoplay],
      injectStylesUrls: [
        PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'
      ],
      injectStyles: [
          `.unpaid-swiper-order {
            overflow: visible;
          }
          `,
          `.swiper-pagination {
            position: static !important;
          }
          `,
          `.swiper-pagination-bullet {
            width: 0.585rem !important;
            height: 0.08rem !important;
            display: inline-block !important;
            background: #302b2b !important;
            border-radius: 0 !important;
            margin: 0 !important
          }
          `,
          `.swiper-pagination-bullet-active {
            background: #666 !important;
          }
          `,
          `.swiper-pagination-lock {
            background: transparent;
            display: ${getUnpaidOrdersFloor.value.length == 1 ? 'block' : 'none'};
            height: 18px;
          }
          `,
      ],
      spaceBetween: 20,
      initialSlide: realIndex.value
    }
    
    // swiperInstance
    Object.assign(swiperEl, swiperConfig)
    swiperEl.initialize()
    swiperInstance.value = swiperEl.swiper
    const isStopAutoplay = ['unpaid-order-box__hide', 'unpaid-order-box__default'].includes(unpaidOrderActiveClass.value)
    if(isStopAutoplay) {
      swiperInstance.value.autoplay.stop()
    }
  } else {
    // 在 Swiper 中使用循环模式（loop）时，会遇到一个已知问题：由于 Swiper 为了实现循环效果，在轮播的开始和结束处各复制了若干张幻灯片，当手动快速滑动到轮播的开始或结尾时，可能会导致内容的不一致。这通常发生在从最后一张滑动到第一张或者从第一张滑动到最后一张时
    if(oldVal?.length == 1 && newVal?.length > 1) {
      swiperInstance.value.loopDestroy()
      swiperInstance.value.loopCreate()
      swiperInstance.value.autoplay.start()
    }
    swiperInstance.value.update()
  }
}

// swiper切换回调
const sliderChangeCallback = () => {
  realIndex.value = swiperInstance.value?.realIndex
}

const clickCloseDialog = () => {
  unpaidDialogHasClicked.value = true
  if (unpaidOrderActiveClass.value === 'unpaid-order-box__default') {
    swiperInstance.value?.slideToLoop(0)
    swiperInstance.value.autoplay.start()
  }
}

/* watch */
watch(getOrdersList, (newVal) => {
  if (newVal.length) {
    nextTick(() => {
      initSwiper()
      setTimeout(() => {
        unpaidOrderRects.value = unpaidOrderRef.value?.getBoundingClientRect()
      }, 1500)
    })
  }
}, { deep: true, immediate: true })


watch(unpaidOrderActiveClass, (newVal) => {
  if(newVal == 'unpaid-order-box__show') {
    const indexActive = getOrdersList.value.findIndex(el => el.billNo == dialogOrderItem.value.billNo)
    swiperInstance.value?.slideToLoop(indexActive)
    // swiperInstance.value?.loopCreate()
    swiperInstance.value?.autoplay?.start()
  }
})

const unpaidOrderListAbtDiversionReport = () => {
  const { diversionBuried } = props.orderGroup || {}
  if (!diversionBuried) return
  diversionBuried.forEach(item => {
    daEventCenter.triggerNotice({
      daId: '1-25-11-1002',
      extraData: item.defaultParams,
    })
  })
}
</script>
<style scoped lang="less">
swiper-container::part(container) {
  overflow: visible;
}
@keyframes backgroundFade {
  0%,66% { background-color: #FFF6F3; }
  33%,100% { background-color: #F6F6F6; }
}
.unpaid-order-box {
  width: 9.36rem;
  margin: 0 auto;
  border-bottom: .29rem solid #fff;
  background-color: #F6F6F6;
  &__single {
    margin-bottom: 10px;
  }
  &__hide {
    opacity: 1;
  }
  &__show {
    animation: backgroundFade .8s forwards;
  }
  .unpaid-swiper-order {
    display: flex;
    flex-wrap: nowrap;
  }
  .unpaid-slide-order {
    flex-shrink: 0;
    padding: 24/75rem 16/75rem;
  }
  .unpaid-slide-order__container {
    display: flex;
    align-items: center;

    // 待评论
    .unreview__container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .order-info {
        flex: 1;
        display: flex;
        .order-img {
          position: relative;
          width: 72/75rem;
          height: 72/75rem;
          overflow: hidden;

          .first-img {
            width: 100%;
            height: 100%;
            display: block;
            border: none;
          }
        }
        .order-content{
          .margin-l(16/75rem);
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          color: #222;
          .font-dpr(28px);
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
