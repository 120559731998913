var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.CommonWrapper,{attrs:{"rights-info":_vm.rightsInfo}},[_c('div',{staticClass:"new-user-benefits__deliver-goods",on:{"click":function($event){$event.stopPropagation();return _setup.onModuleClick.apply(null, arguments)}}},_vm._l((_setup.products),function(product,index){return _c('div',{directives:[{name:"expose",rawName:"v-expose",value:({
        id: '1-25-11-28',
        code: 'new-user-benefits-expose-goods',
        data: {
          product,
          content_list: _setup.getCccJumpUrl(_vm.hrefInfo),
        },
      }),expression:"{\n        id: '1-25-11-28',\n        code: 'new-user-benefits-expose-goods',\n        data: {\n          product,\n          content_list: getCccJumpUrl(hrefInfo),\n        },\n      }"}],key:product.goodID,staticClass:"new-user-benefits__deliver-goods-item",style:({
        backgroundImage: `url(${product.url})`
      }),on:{"click":function($event){$event.stopPropagation();return _setup.onGoodsItemClick(product, index)}}},[(product.discountRateText)?_c('div',{staticClass:"new-user-benefits__deliver-goods-discount",domProps:{"textContent":_vm._s(product.discountRateText)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"new-user-benefits__deliver-goods-price"},[_c(_setup.CamelCasePrice,_vm._b({staticClass:"new-user-benefits__goods-price-camelcase",attrs:{"show-camel-price":true}},'CamelCasePrice',_setup.getCamelCasePrice(product),false))],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }