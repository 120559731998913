/**
 * @file 个人中心埋点
 */

import { abtUserAnalysis } from '@shein-aidc/basis-abt-router'

export const useUserCenterAnalysis = ({ getUserLevelInfo, getPersonalCenterEntranceInfo, isLogin }) => {
  const reportPagePv = ({
    isLogin,
  }) => {
    const abtestVal = abtUserAnalysis({ posKeys: 'Reviewentry' }).sa
    const vip_level = getUserLevelInfo.value?.level || null
  
    window.SaPageInfo = {
      page_id: 115,
      page_name: 'page_me',
      page_param: {
        vip_level,
        is_logined: isLogin ? 1 : 0,
        abtest: abtestVal,
      },
      start_time: Date.now(),
    }
    sa('set', 'setPageData', window.SaPageInfo)
    window.appEventCenter.$emit('pageOnload')
  }

  const triggerPageAnalysis = ({ needPV, needExpose }) => {
    const cardList = getPersonalCenterEntranceInfo.value?.member_card_list || []
    needPV && reportPagePv({
      isLogin: isLogin.value,
      cardList,
    })
    needExpose && window.daEventCenter.getExposeInstance()?.resetAll?.()
  }

  return {
    triggerPageAnalysis,
  }
}
