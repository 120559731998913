<template>
  <s-dialog
    :visible.sync="showDialog"
    :close-on-click-modal="false"
    class="gift-dialog-modal"
    :immediately-render="false"
    :show-close="true"
    modal-class="gift-dialog-modal"
    append-to-body
    :style="{
      '--justify-content': productLength === 1 ? 'center' : (productLength === 2 ? 'flex-start' : 'space-between'),
    }"
    @close-from-icon="clickCloseIcon"
  >
    <div
      v-if="!hidden"
      class="fire"
    >
      <img
        :src="popupInfo?.popupGif"
        alt=""
      />
    </div>
    <div class="gift-border"></div>
    <img
      class="header-icon"
      :src="popupInfo?.popupLogo"
      alt=""
    />
    <div
      v-expose="{
        id: '1-25-15-1',
        data: {
          scene_type: freeGiftPopupInfo?.sceneType,
        }
      }"
      class="gift-background free-gift-animation"
    >
      <div class="gift-main__title">
        <ResizeFont>
          <ResizeFontText
            :init-font-size="14"
            :resize-font-min="10"
            :resize-font-step="1"
            :content="popupInfo?.title"
          />
        </ResizeFont>
      </div>
      <div
        class="gift-sub__title"
        style=""
      >
        <ResizeFont>
          <ResizeFontText
            class="gift-sub__title-value"
            :init-font-size="22"
            :resize-font-min="18"
            :resize-font-step="1"
            :content="popupInfo?.subTitle"
          />
        </ResizeFont>
      </div>
      <div
        v-if="freeGiftPopupInfo?.countDown"
        class="expire-time"
      >
        <div class="expire-text">
          {{ popupInfo?.countDownText || "Expires" }}
        </div>
        <BaseCountdown
          v-if="countTimer"
          :count-timer="countTimer"
          class="count-timer"
        />
      </div>
      <div
        ref="carouselInner"
        class="gift-product__list"
        :class="{
          'gift-product__list--animation': productLength > 1
        }"
      >
        <product-item
          v-for="(item,index) in giftInfoList"
          :key="index"
          class="product-item"
          :class="{'active': index === activeIndex}"
          :item="item"
          :popup-info="popupInfo"
          :style="productStyle(index)"
        />
      </div>
    </div>
    <div
      v-tap="{
        id: '1-25-15-2',
        data: {
          scene_type: freeGiftPopupInfo?.sceneType,
        }
      }"
      class="pick-btn"
      @click="goPrimePage"
    >
      {{ popupInfo?.buttonText || 'Pick Now' }}
      <sweep-light class="light" />
    </div>
  </s-dialog>
</template>

<script>
import schttp from 'public/src/services/schttp'
import ProductItem from './ProductItem.vue'
import { Dialog as SDialog } from '@shein/sui-mobile'
import { CountDown } from '@shein/common-function'
import ResizeFont from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFont.vue'
import ResizeFontText from 'public/src/pages/components/ccc/base/ResizeFont/ResizeFontText.vue'
import SweepLight from './SweepLight.vue'
import BaseCountdown from './BaseCountdown.vue'
import { daEventCenter } from '@/public/src/services/eventCenter'

const { langPath } = gbCommonInfo || {}
daEventCenter.addSubscriber({ modulecode: '1-25-15' })

export default {
  name: 'FreeGiftDialog',
  components: {
    ResizeFont,
    ResizeFontText,
    ProductItem,
    BaseCountdown,
    SDialog,
    SweepLight,
  },
  props: {
    freeGiftPopupInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeIndex: 0,
      hidden: false,
      showDialog: true,
      countTimer: new CountDown(),
    }
  },
  computed: {
    popupInfo() {
      return this.freeGiftPopupInfo?.popupInfo
    },
    giftInfoList() {
      return this.freeGiftPopupInfo?.giftInfoList?.slice(0, 3) ?? []
    },
    productLength() {
      return this.giftInfoList?.length ?? 0
    },
    productStyle() {
      return (index) => {
        if(index === 1 && this.productLength === 2){
          return {
            margin: '0 0.6rem',
          }
        }
        return {}
      }
    }
  },
  mounted() {
    this.countTimer.start({
      seconds: this.freeGiftPopupInfo?.countDown || 0, // 秒
      endFunc: () => {
        // TODO
      }
    })
    this.$nextTick(()=>{
    })
    setTimeout(() => {
      this.init()
    }, 1500)
    setTimeout(() => {
      this.hidden = true
    }, 1650)
    this.exposeGiftDialog()
  },
  methods: {
    init() {
      const carouselInner = this.$refs.carouselInner
      function stopAnimation() {
        // 在动画达到预计时间后停止动画并保持当前位置
        carouselInner.style.animationPlayState = 'paused'
      }
      
      const length = this.productLength ?? 0
      
      const ANIMATION_DURATION = {
        0: 0,
        2: 1350,
        3: 3000,
      }
      // 在动画达到第四个元素时停止
      setTimeout(stopAnimation, ANIMATION_DURATION[length]) // 动画总时长的 75%
      if(length === 1) return
      const timer = setInterval(() => {
        this.activeIndex += 1
        if (this.activeIndex >= length - 1 ) {
          clearInterval(timer)
        }
      }, 900)
    },
    goPrimePage() {
      this.showDialog = false
      const defaultUrl = '/user/prime?productsource=freeGiftPop'
      this.$router.push(
        `${langPath}${this.popupInfo?.freeGiftUrl || defaultUrl}`
      )
    },
    async exposeGiftDialog() {
      await schttp({
        url: `/user/center/free_gift_popup_exposure`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        useBffApi: true,
        data: {
          billno: this.freeGiftPopupInfo.billNo,
          cycle_num: this.freeGiftPopupInfo.cycleNum,
          scene_type: this.freeGiftPopupInfo.sceneType,
        }
      })
    },
    clickCloseIcon(){
      daEventCenter.triggerNotice({
        daId: '1-25-15-3',
        bindData: {
          scene_type: this.freeGiftPopupInfo?.sceneType,
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
// 设计稿用的 375px
@unit: 100/375vw;

.gift-dialog-modal {
  background: rgba(0, 0, 0, 0.7);
  .fire {
    position: absolute;
    width: 125%;
    left: 50%;
    top: -20%;
    transform: translateX(-50%);
    height: 200 * @unit;
    
    img {
      width: 100%;
    }
  }
  
  .gift-border {
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    top:50%;
    transform: translate(-50%,-50%);
    border-radius: 20 * @unit;
    border: 1px solid rgba(252, 224, 195, 0.3);
    width: calc(100% - 10 * @unit);
    height: calc(100% - 10 * @unit);
  }
  /deep/ .S-dialog__body {
    height: fit-content;
    padding: 0;
    padding-top: 15 * @unit;
    padding-bottom: 85 * @unit;
    overflow: hidden;
  }
  
  .gift-background {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transform: translate3D(0, 0, 0) rotate(0.00deg) scale(0.00, 0.00);
    opacity: 0.00;
    transform-origin: 50.00% 50.00%;
    display: block;
    animation: gift-bg-animation 5.76s linear 0.00s 1 normal forwards;
  }
  
  .header-icon {
    position: absolute;
    width: 100 * @unit;
    height: 110 * @unit;
    z-index: 10;
    left: 50%;
    top: -16%;
    transform: translateX(-50%);
  }
  
  .gift-main__title {
    text-align: center;
    margin-bottom: 5 * @unit;
    padding: 10 * @unit 25 * @unit;
    color: #c76134;
    font-weight: 600;
  }
  
  .gift-sub__title {
    text-align: center;
    color: #c96235;
    padding: 0 10 * @unit;
    line-height: 1;
    font-weight: 860;
    &-value {
      .line-camp(2);
      white-space: break-spaces;
    }
  }
  
  
  /deep/ .S-dialog__wrapper {
    background:
      linear-gradient(75deg, #FFFBF4 0%, #FFEFDB 100%),
      linear-gradient(45deg, #FFFBEF 0%, #FFE2BC 100%),
      linear-gradient(82deg, #FFFAF5 51.65%, #FFF1E9 100%),
      linear-gradient(143deg, rgba(251, 183, 106, 0.3) 8.33%, rgba(255, 205, 148, 0) 100%),
      linear-gradient(30deg, rgba(236, 170, 103, 0.1) 9.9%, rgba(255, 201, 146, 0) 100%);
    
    border-radius: 20 * @unit;
    width: 100%;
    max-width: 80%;
    box-shadow: 0 0 10 * @unit 10 * @unit rgba(251, 183, 106, 0.3); /* 实现四边5px的阴影 */
  }
  
  /deep/ .S-dialog__closebtn {
    width: 30 * @unit;
    height: 30 * @unit;
    line-height: 26.25 * @unit;
    font-size: 18 * @unit;
    top: unset;
    bottom: -50 * @unit;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    color: #fff !important;
  }
  
  
  .expire-time {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10 * @unit 0 6 * @unit;
    
    .expire-text {
      margin-right: 10 * @unit;
      font-size: 12 * @unit;
      color: rgba(135, 60, 0, 1);
    }
  }
  
  [mir=rtl]{
    .base-countdown {
      direction: ltr;
    }
  }
  
  .gift-product__list {
    width: 100%;
    padding-top: 10%;
    padding-bottom: 15%;
    display: flex;
    //overflow: hidden;
    justify-content: var(--justify-content);
    .product-item {
      flex-shrink: 0;
      background-size: 100% 100%;
      height: 90 * @unit;
      width: 83 * @unit;
      flex-basis: 83 * @unit;
      //margin: 0 8 * @unit;
      transition: all ease-in-out 0.2s;
      border-radius: 6 * @unit;
      
      &:nth-child(2) {
        margin:0 15 * @unit;
      }
      
      &.active {
        transform: scale(1.333);
      }
    }
  }
  .gift-product__list--animation {
    transform: translate3D(33.33%, 0.00px, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
    animation: slide 6s linear forwards;
    animation-delay: 1.5s;
  }
  
  @keyframes slide {
    
    0%, 8%, 10% {
      transform: translate3D(33.33%, 0px, 0px) rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    11%,17% {
      opacity: 0.7;
    }
    18%, 20%, 28%, 30% {
      transform: translate3D(-5%, 0px, 0px) rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    31%, 37% {
      opacity: 0.7;
    }
    38%, 40%, 48%, 50% {
      transform: translate3D(-43.33%, 0px, 0px) rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    51%, 57% {
      opacity: 0.7;
    }
    58%, 60%, 68%, 70% {
      transform: translate3D(-100.66%, 0.00px, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
    }
  }
  
  .pick-btn {
    .text-overflow();
    position: absolute;
    padding: 0 8 * @unit;
    background: linear-gradient(90deg, #FFC3A5 0%, #EAA27E 100%),
    linear-gradient(90deg, #F7C695 0%, #EDA65F 100%);
    left: 50%;
    bottom: 7%;
    transform: translateX(-50%);
    transform: translate3D(0, 0, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
    opacity: 0.00;
    transform-origin: 50.00% 50.00%;
    border-radius: 20 * @unit;
    color: rgba(173, 82, 9, 1);
    width: 215 * @unit;
    height: 40 * @unit;
    line-height: 40 * @unit;
    text-align: center;
    font-size: 18 * @unit;
    font-weight: 700;
    animation: btn-animation 5.76s linear 0.00s 1 normal forwards;
    
    .light {
      position: absolute;
      left: -15 * @unit;
      opacity: 0;
      animation: moveRight 2.5s forwards; /* 应用动画, 2.5秒内完成, 并保持动画结束后的状态 */
      animation-delay: 1.5s; /* 延迟1.5秒后开始动画 */
    }
    
    @keyframes moveRight {
      from {
        left: 0;
        opacity: 1;
      }
      to {
        left: 100%;
        opacity: 0;
      }
    }
    
  }
  
  @keyframes btn-animation {
    0.00% {
      opacity: 0.00;
    }
    0.69% {
      opacity: 0.10;
    }
    1.39% {
      opacity: 0.20;
    }
    2.08% {
      opacity: 0.30;
    }
    2.78% {
      opacity: 0.40;
    }
    3.47% {
      opacity: 0.50;
    }
    4.17% {
      opacity: 0.60;
    }
    4.86% {
      opacity: 0.70;
    }
    5.56% {
      opacity: 0.80;
    }
    6.25% {
      opacity: 0.90;
    }
    6.94% {
      opacity: 1.00;
    }
    100.00% {
      opacity: 1.00;
    }
  }
  
  .free-gift-animation{
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    animation:free-gift-animation-define 5.76s linear 0.00s 1 normal forwards;
  }
  @keyframes free-gift-animation-define{
    0.00% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.00,0.00);opacity: 0.00;}
    0.69% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.11,0.11);opacity: 0.10;}
    1.39% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.22,0.22);opacity: 0.20;}
    2.08% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.33,0.33);opacity: 0.30;}
    2.78% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.44,0.44);opacity: 0.40;}
    3.47% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.55,0.55);opacity: 0.50;}
    4.17% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.66,0.66);opacity: 0.60;}
    4.86% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.77,0.77);opacity: 0.70;}
    5.56% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.88,0.88);opacity: 0.80;}
    6.25% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(0.99,0.99);opacity: 0.90;}
    6.94% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.10,1.10);opacity: 1.00;}
    7.64% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.08,1.08);opacity: 1.00;}
    8.33% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.06,1.06);opacity: 1.00;}
    9.03% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.04,1.04);opacity: 1.00;}
    9.72% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.02,1.02);opacity: 1.00;}
    10.42% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.00,1.00);opacity: 1.00;}
    100.00% {transform: translate3D(0.00px,0.00px,0.00px) rotate(0.00deg) scale(1.00,1.00);opacity: 1.00;}
  }
  
  @keyframes gift-bg-animation {
    0.00% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.00, 0.00);
      opacity: 0.00;
    }
    0.69% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.11, 0.11);
      opacity: 0.10;
    }
    1.39% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.22, 0.22);
      opacity: 0.20;
    }
    2.08% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.33, 0.33);
      opacity: 0.30;
    }
    2.78% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.44, 0.44);
      opacity: 0.40;
    }
    3.47% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.55, 0.55);
      opacity: 0.50;
    }
    4.17% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.66, 0.66);
      opacity: 0.60;
    }
    4.86% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.77, 0.77);
      opacity: 0.70;
    }
    5.56% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.88, 0.88);
      opacity: 0.80;
    }
    6.25% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(0.99, 0.99);
      opacity: 0.90;
    }
    6.94% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.10, 1.10);
      opacity: 1.00;
    }
    7.64% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.08, 1.08);
      opacity: 1.00;
    }
    8.33% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.06, 1.06);
      opacity: 1.00;
    }
    9.03% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.04, 1.04);
      opacity: 1.00;
    }
    9.72% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.02, 1.02);
      opacity: 1.00;
    }
    10.42% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
      opacity: 1.00;
    }
    100.00% {
      transform: translate3D(0.00px, 0.00px, 0.00px) rotate(0.00deg) scale(1.00, 1.00);
      opacity: 1.00;
    }
  }
  
}

</style>
