import { render, staticRenderFns } from "./ModuleIndex.vue?vue&type=template&id=21000d8d&scoped=true&"
import script from "./ModuleIndex.vue?vue&type=script&setup=true&name=UserRightsFusionFloor&lang=js&"
export * from "./ModuleIndex.vue?vue&type=script&setup=true&name=UserRightsFusionFloor&lang=js&"
import style0 from "./ModuleIndex.vue?vue&type=style&index=0&id=21000d8d&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21000d8d",
  null
  
)

export default component.exports