<template>
  <div
    class="base-countdown"
    tabindex="0"
    role="text"
  >
    <span
      v-if="showDay"
      class="base-countdown__num"
      :style="itemStyle"
    >{{ timeObj.D }}</span>
    <span
      class="base-countdown__num"
      :style="itemStyle"
    >{{ timeObj.H }}</span> :
    <span
      class="base-countdown__num"
      :style="itemStyle"
    >{{ timeObj.M }}</span> :
    <span
      class="base-countdown__num"
      :style="itemStyle"
    >{{ timeObj.S }}</span>
  </div>
</template>

<script>
import { CountDown, timeTransformer } from '@shein/common-function'

export default {
  name: 'BaseCountdown',
  props: {
    countTimer: {
      type: Object,
      default: () => {
        return {
          timeObj: {},
        }
      }
    },
    itemStyle: {
      type: Object,
      default: () => ({})
    },
    countStatusText: {
      type: String,
      default: ''
    },
    compLanguage: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    timeObj() {
      return this?.countTimer?.timeObj || {}
    },
    showDay() {
      return this.timeObj.D > 0
    },
  },
  mounted() {
    
  },
  methods: {}
}
</script>

<style lang="less" scoped>
// 设计稿用的 375px
@unit: 100/375vw;

.base-countdown {
  color: #FA6338;
  
  &__num {
    background: rgba(159, 95, 45, 0.1);
    padding: 4 * @unit;
    border-radius: 4 * @unit;
    color: #FA6338;
    min-width: 37.5 * @unit;
    height: 32 * @unit;
    line-height: 25 * @unit;
    padding: 2 * @unit;
  }
}
</style>
