var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('s-dialog',{staticClass:"payback-dialog",attrs:{"visible":_setup.currentDialogShow,"show-close":false,"close-on-click-modal":false,"append-to-body":""}},[_c('div',{directives:[{name:"expose",rawName:"v-expose",value:(
      _setup.getAnalysisData({
        id: '1-25-11-1014',
        data: {
          bottomtype: _setup.getPayBackBuries.defaultParams?.bottomtype
        }
      })
    ),expression:"\n      getAnalysisData({\n        id: '1-25-11-1014',\n        data: {\n          bottomtype: getPayBackBuries.defaultParams?.bottomtype\n        }\n      })\n    "}],staticClass:"payback-dialog__wrapper",class:{
      'payback-dialog__wrapper-animate': _setup.visibility === 'visible'
    }},[_c('p',{staticClass:"payback-dialog__title"},[_vm._v("\n      "+_vm._s(_setup.getPopupsInfo.popups_title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"payback-dialog__content-img",style:({ backgroundImage: `url(${_setup.getPopupsInfo.badges_img_url})` })},[_c('span',{staticClass:"payback-roi__wrapper"},[_c('span',{staticClass:"payback-roi__top"},[_vm._v("\n          "+_vm._s(_setup.template(_setup.getPopupsInfo.roi, _setup.getPopupsInfo.roi_tips))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"payback-roi__bottom"},[_vm._v("\n          "+_vm._s(_setup.template(_setup.getPopupsInfo.roi, _setup.getPopupsInfo.roi_tips))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"payback-dialog__content"},[_c('div',{staticClass:"payback-dialog__bg",style:({ backgroundImage: `url(${_setup.getPopupsInfo.underline_img_url})` })},[_c('div',{staticClass:"payback-dialog__main"},[_c('p',{staticClass:"payback-dialog__content-title",domProps:{"innerHTML":_vm._s(_setup.getPaybackDialogTitle)}}),_vm._v(" "),_c('div',{staticClass:"beyond-line"},[_c('div',{staticClass:"beyond-line__dot"})]),_vm._v(" "),_c('div',{staticClass:"payback-dialog__content-beyond"},[_c('div',{staticClass:"beyond-animation"}),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_setup.getPaybackDialogBeyond)}})]),_vm._v(" "),_c('div',{staticClass:"beyond-line"},[_c('div',{staticClass:"beyond-line__dot"})]),_vm._v(" "),_c('div',{directives:[{name:"tap",rawName:"v-tap",value:(
              _setup.getAnalysisData({
                id: '1-25-11-1015',
                data: {
                  bottomtype: _setup.getPayBackButtonBuries.defaultParams?.bottomtype
                }
              })
            ),expression:"\n              getAnalysisData({\n                id: '1-25-11-1015',\n                data: {\n                  bottomtype: getPayBackButtonBuries.defaultParams?.bottomtype\n                }\n              })\n            "}],staticClass:"payback-dialog__content-btn",style:({ backgroundImage: `url(${_setup.getPopupsInfo.button_img_url})` }),on:{"click":_setup.closeHandle}},[(_setup.getPopupsInfo.button_bubble)?_c('div',{staticClass:"payback-dialog__btn-tip"},[_c('img',{attrs:{"src":_setup.getPopupsInfo.button_img_url}}),_vm._v("\n              "+_vm._s(_setup.getPopupsInfo.button_bubble)+"\n            ")]):_vm._e(),_vm._v(" "),_c('span',{style:({ 'margin-top': '-14px' })},[_vm._v(_vm._s(_setup.getPopupsInfo.button_tips))])])])])]),_vm._v(" "),_c('i',{staticClass:"payback-dialog__close suiiconfont sui_icon_close_24px",on:{"click":function($event){return _setup.closeHandle('close')}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }